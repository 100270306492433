import React from "react";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import FootBar from "../components/navigations/FootBar";
import MiddleOne from "../components/navigations/MiddleOne";
import {useTranslation} from "react-i18next";
import "../translations/i18n";
import {getCustomers, getPartners} from "../utils/Common";
//import Carousel from 'carousel-react-rcdev'
import {StaticImage} from "gatsby-plugin-image";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Partner = () => {

    const {t} = useTranslation();
    const customers = getCustomers();
    const partners = getPartners();
    return (
        <Layout pageTitle={t("our_partners")}>
            <div className="min-h-full bg-gray-50">
                <NavBar active={'partners'}/>
                <div className="px-4 py-20 sm:p-20 bg-custom-block">
                    <div
                        className="relative flex flex-col items-center max-w-2xl px-6 py-10 mx-auto text-center sm:my-15 lg:px-0">
                        <h1 className="max-w-2xl text-4xl font-normal tracking-tight text-white lg:text-5xl uppercase">{t("our")}</h1>
                        <h1 className="max-w-2xl text-4xl font-extrabold tracking-tight text-white lg:text-5xl uppercase">{t("customers")}</h1>
                    </div>
                </div>

                <div className="-mt-16 mb-36">
                    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-none lg:px-8">
                        {/* Main 3 column grid */}
                        <div className="grid grid-cols-1 gap-4 items-start sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
                            {customers.map((customer) => (
                                <div className="grid grid-cols-1 gap-4 mb-5">
                                    {/* SABLUX */}
                                    <section aria-labelledby={customer.labelledby}>
                                        <div className="bg-white overflow-hidden shadow">
                                            <div className="p-6">
                                                <a title={customer.name}
                                                   key={customer.labelledby}
                                                   href={customer.href}
                                                   target="_blank"
                                                   rel="noreferrer"
                                                >
                                                    <div aria-hidden="true">
                                                        <img src={'clients/'+customer.image}
                                                             alt={customer.name}
                                                             className="object-cover object-center w-full h-full"
                                                             width={550}
                                                             height={210}
                                                        />
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="px-4 py-20 sm:p-20 bg-custom-block">
                    <div
                        className="relative flex flex-col items-center max-w-2xl px-6 py-10 mx-auto text-center sm:my-15 lg:px-0">
                        <h1 className="max-w-2xl text-4xl font-normal tracking-tight text-white lg:text-5xl uppercase">{t("our")}</h1>
                        <h1 className="max-w-2xl text-4xl font-extrabold tracking-tight text-white lg:text-5x uppercasel">{t("partners")}</h1>
                    </div>
                </div>
                <div className="-mt-10 mb-20">
                    <div className="grid grid-cols-1 content-center h-48  px-14 sm:px-6 lg:max-w-none lg:px-8">
                      <Carousel autoPlay={true} infiniteLoop={true}  className="center-item">
                            {partners.map((partner) => (
                                    <img
                                        src={'partenaires/' + partner.image}
                                        alt={partner.name}
                                        className="object-cover object-center w-full h-full bg-white"
                                    />
                            ))}
                        </Carousel>
                    </div>
                </div>
                <MiddleOne/>
                <FootBar active={'partner'}/>
            </div>

        </Layout>
    );
}

export default Partner;
