import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "gatsby";
import {classNames} from "../../utils/Common";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
function renderSwitch(param, label){
  switch(param) {
    case 'solution':
      return <img
          className="w-auto h-auto"
          src={'logos/logo-sepro-solutions-dark.png'}
          alt={label}
      />;
    case 'technology':
      return <img
          className="w-auto h-auto"
          src={'logos/logo-sepro-tech.png'}
          alt={label}
      />;

    default:
      return <img
          className="w-auto h-auto"
          src={'logos/logo-sepro-holding-dark.png'}
          alt={label}
      />;
  }
}


const NavBar = (props) => {
  const { t } = useTranslation();
  return (
    <Popover className="relative bg-white ">
      <div className="px-4 mx-auto max-w-8xl sm:px-6">
        <div className="flex items-center justify-between md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">{t("sepro_holding")}</span>
              {renderSwitch(props.page)}
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-cyan-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="w-6 h-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden space-x-10 md:flex">
            <Link
              to="/"
              className="text-sm font-medium text-gray-900 hover:text-cyan-500 uppercase font-family-menu mt-1 md:text-base"
            >
              {t("home")}
            </Link>
            <Link
                to="/about"
                className={props.active === "about" ? "text-base font-medium text-cyan-900 hover:text-cyan-500 uppercase font-family-menu mt-1" : "text-base font-medium text-gray-900 hover:text-cyan-500 uppercase font-family-menu mt-1"}
            >
              {t("qui_sommes_nous")}
            </Link>
            <Popover className="relative">
                <>
                  <Popover.Button
                    className={classNames(
                        props.active === "solution" || props.active === "technology" ? "text-cyan-900" : "text-gray-900",
                      "group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-cyan-500 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500 uppercase font-family-menu"
                    )}
                  >
                    <span>{t("our_activities")}</span>
                    <ChevronDownIcon
                      className={classNames(
                          props.active === "solution" || props.active === "technology" ? "text-cyan-900" : "text-gray-900",
                        "ml-2 h-5 w-5 group-hover:text-cyan-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8">
                          <Link
                            to="/sepro-solution"
                            className="flex items-start p-3 -m-3 rounded-lg"
                          >
                            <p className={classNames(
                                props.active === "solution" ? "text-cyan-600" : "text-gray-900",
                                "text-base font-medium text-gray-500 hover:text-cyan-500 uppercase font-family-menu"
                            )}>
                              {t("sepro_solution")}
                            </p>
                          </Link>
                          <Link
                            to="/sepro-technology"
                            className="flex items-start p-3 -m-3 rounded-lg"
                          >
                            <p className={classNames(
                                props.active === "technology" ? "text-cyan-600" : "text-gray-900",
                                "text-base font-medium text-gray-500 hover:text-cyan-500 uppercase font-family-menu"
                            )}>
                              {t("sepro_technology")}
                            </p>
                          </Link>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>

            </Popover>

            <Link
              to="/partner"
              className={props.active === "partner" ? "text-base font-medium text-cyan-900 hover:text-cyan-500 uppercase font-family-menu mt-1" : "text-base font-medium text-gray-900 hover:text-cyan-500 uppercase font-family-menu mt-1"}
            >
              {t("our_partners")}
            </Link>
          </Popover.Group>
          <div className="items-center justify-end hidden md:flex md:flex-1 lg:w-0">
            <Link
              to="/contact" className={classNames(
                props.active === "contact" ? "bg-teal-900" : " bg-cyan-900",
                "inline-flex items-center justify-center px-4 py-2 ml-8 text-base font-medium text-white border border-transparent shadow-sm whitespace-nowrap hover:bg-teal-900 uppercase font-family-menu"
            )}
            >
              {t("contact_us")}
            </Link>
          </div>
        </div>
      </div>

      <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
            focus
            className="absolute z-50 inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden"
        >
          <div className="bg-middle z-50  divide-y-2 rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-gray-50">
            <div className="px-5 pt-5 pb-6 z-50 ">
              <div className="flex items-center justify-between">
                <div>
                  <img
                      className="w-auto h-8"
                      src={"logos/logo-sepro-holding.png"}
                      alt={t("sepro_holding")}
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-100 bg-cyan-900 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  <Link
                      to="/"
                      className="flex items-center p-3 -m-3 hover:bg-gray-5°0"
                  >
                    <span className="ml-3 text-base font-medium text-gray-200 uppercase">
                      {t("home")}
                    </span>
                  </Link>
                  <Link
                      to="/about"
                      className="flex items-center p-3 -m-3 hover:bg-gray-500"
                  >
                    <span className={classNames(
                        props.active === "about" ? "text-cyan-500" : "text-gray-200",
                        "ml-3 text-base font-medium uppercase"
                    )}>
                      {t("qui_sommes_nous")}
                    </span>
                  </Link>
                  <Link
                      to="/sepro-solution"
                      className="flex items-center p-3 -m-3 hover:bg-gray-500"
                  >
                    <span className={classNames(
                        props.active === "solution" ? "text-cyan-500" : "text-gray-200",
                        "ml-3 text-base font-medium uppercase"
                    )}>
                      {t("sepro_solution")}
                    </span>
                  </Link>
                  <Link
                      to="/sepro-technology"
                      className="flex items-center p-3 -m-3 hover:bg-gray-500"
                  >
                    <span className={classNames(
                        props.active === "technology" ? "text-cyan-500" : "text-gray-200",
                        "ml-3 text-base font-medium uppercase"
                    )}>
                      {t("sepro_technology")}
                    </span>
                  </Link>

                  <Link
                      to="/partner"
                      className="flex items-center p-3 -m-3 hover:bg-cyan-500"
                  >
                    <span className={classNames(
                        props.active === "partners" ? "text-cyan-500" : "text-gray-200",
                        "ml-3 text-base font-medium uppercase"
                    )}>
                      {t("our_partners")}
                    </span>
                  </Link>
                </nav>
              </div>
            </div>
            <div className="px-5 py-3 space-y-6">

              <Link
                  to="/contact"
                  className="flex items-center p-3 -m-3 hover:text-cyan-500"
              >
                    <span className={classNames(
                        props.active === "contact" ? "text-cyan-500" : "text-gray-200",
                        "ml-3 text-base font-medium uppercase"
                    )}>
                      {t("contact_us")}
                    </span>

              </Link>


            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
export default NavBar;
